<template>
  <b-container fluid>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto" class="mb-3">
        <h3>{{ salutation }}</h3>
      </b-col>
    </b-row>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">
        <!--
        <h5>Hier finden Sie Ihre Aufgaben für die <u>wöchentliche</u> Datenerfassung.</h5>

        <br/>
        <div
            v-if="recordingDatesCounter('sumWeekly') > 0"
            >
          <p>Bitte tragen Sie <strong>{{ recordingDatesCounter('sumWeekly') }}</strong> Datensätze ein.<br/>
          Umsätze: <strong>{{ recordingDatesCounter('turnover') }}</strong><br/>
          Besucher: <strong>{{ recordingDatesCounter('visitor') }}</strong></p>
          <p>
            <b-button
                type="button"
                variant="primary"
                class="mt-2 mb-4 button-primary"
                to="/recordingWeeks"
            >Wochendaten erfassen</b-button></p>
        </div>

        <div
          v-else>
          <p>Vielen Dank! Sie haben aktuell alle Daten eingetragen.</p>
        </div>

        <br/>

        <h5>Hier finden Sie Ihre Aufgaben für die <u>monatliche</u> Datenerfassung.</h5>
        <br/>
        <div
            v-if="recordingMonthCounter('sumMonthly') > 0"
        >
          <p>Bitte tragen Sie <strong>{{ recordingMonthCounter('sumMonthly') }}</strong> Datensätze ein.<br/>
            Umsätze: <strong>{{ recordingMonthCounter('turnover') }}</strong><br/>
            Besucher: <strong>{{ recordingMonthCounter('visitor') }}</strong></p>
          <p>
            <b-button
                type="button"
                variant="primary"
                class="mt-2 mb-4 button-primary"
                to="/recordingMonths"
            >Monatsdaten erfassen</b-button></p>
        </div>

        <div
            v-else>
          <p>Vielen Dank! Sie haben aktuell alle Daten eingetragen.</p>
        </div>

        <br/>
        <h5>Hier finden Sie Ihre Aufgaben für die <u>jährliche</u> Datenerfassung.</h5>
        <br/>
        <div
            v-if="recordingYearsCounter('sumYears') > 0"
        >
          <p>Bitte tragen Sie <strong>{{ recordingYearsCounter('sumYears') }}</strong> Datensätze ein.<br/>
            Mitarbeiter: <strong>{{ recordingYearsCounter('staff') }}</strong><br/>
            Besucher nach Regionen: <strong>{{ recordingYearsCounter('visitor') }}</strong>
          </p>

          <p>
            <b-button
                type="button"
                variant="primary"
                class="mt-2 mb-4 button-primary"
                to="/recordingYears"
            >Jahresdaten erfassen</b-button></p>
        </div>

        <div
            v-else>
          <p>Vielen Dank! Sie haben aktuell alle Daten eingetragen.</p>
        </div>
        -->





        <h5>Hier finden Sie Ihre Aufgaben für die <u>monatliche</u> Datenerfassung.</h5>
        <br/>
        <div
            v-if="getCompanyStatistic.countOpenMonths > 0"
        >
          <p>Bitte tragen Sie <strong>{{ getCompanyStatistic.countOpenMonths }}</strong> Datensätze ein.<br/>
            Umsätze: <strong>{{ getCompanyStatistic.countOpenEarningsMonths }}</strong><br/>
            Besucher: <strong>{{ getCompanyStatistic.countOpenVisitorsMonths }}</strong></p>
          <p>
            <b-button
                type="button"
                variant="primary"
                class="mt-2 mb-4 button-primary"
                to="/recordingMonths"
            >Monatsdaten erfassen</b-button></p>
        </div>

        <div
            v-else>
          <p>Vielen Dank! Sie haben aktuell alle Daten eingetragen.</p>
        </div>

        <br/>
        <h5>Hier finden Sie Ihre Aufgaben für die <u>jährliche</u> Datenerfassung.</h5>
        <br/>
        <div
            v-if="getCompanyStatistic.countOpenYears > 0"
        >
          <p>Bitte tragen Sie <strong>{{ getCompanyStatistic.countOpenYears }}</strong> Datensätze ein.<br/>
            Mitarbeiter: <strong>{{ getCompanyStatistic.countOpenStaffAnnually }}</strong><br/>
            Besucher nach Regionen: <strong>{{ getCompanyStatistic.countOpenVisitorTypesAnnually }}</strong>
          </p>

          <p>
            <b-button
                type="button"
                variant="primary"
                class="mt-2 mb-4 button-primary"
                to="/recordingYears"
            >Jahresdaten erfassen</b-button></p>
        </div>

        <div
            v-else>
          <p>Vielen Dank! Sie haben aktuell alle Daten eingetragen.</p>
        </div>



        <br/>
        <h5>Sie sind ein junges Unternehmen?</h5>
        <br/>
        <div>
          <p>Wählen Sie ein alternatives Start-Datum, ab dem statistische Informationen für Ihr Unternehmen erfasst werden sollen.</p>

          <div class="mt-3 w-50">
            <p>Start-Datum (optional):</p>
            <b-form-datepicker
                id="statistic_start_date"
                v-model="statistic_start_date"
                class="mb-2"
                :min="min_date"
                :max="max_date"
            ></b-form-datepicker>
          </div>

          <b-alert
              :show="dismissCountDown"
              dismissible
              fade
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
              class="w-50"
          >
            <p>Vielen Dank!</p>
            <p>Das Datum wurde gespeichert.</p>
            <b-progress
                variant="success"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <p>
            <b-button
                type="button"
                variant="primary"
                class="mt-2 mb-4 button-primary"
                @click="saveCompanyStatisticStartDate"
            >speichern</b-button></p>

        </div>

      </b-col>
    </b-row>


  </b-container>
</template>

<script>

import { mapMutations, mapGetters, mapActions } from 'vuex';
import axios from "axios";

export default {
  name: "areaRecording",
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      months: {},
      min_date: '2020-12-01',
      max_date: new Date().toISOString().slice(0, 10),
      statistic_start_date: '',
      dismissSecs: 4,
      dismissCountDown: 0,
      showDismissibleAlert: false,
    }
  },
  mounted () {

    if (!this.$store.getters.getIsLoggedIn) {
      this.$router.push('/login');
    }

  },
  created() {

    /*
    Start-Datum: entweder seit Anbegin, oder am 01.01. des Vorjahres oder das definierte statistic_start_date
     */

    var startDate = this.$store.getters.getRecordingStartDate;
    var today = new Date();

    //das war für die Wochendaten
    /*
    const lastSunday = new Date();
    lastSunday.setDate(today.getDate() - today.getDay());
    var lastSundayFormatted = new Date(lastSunday).toISOString().slice(0, 10);
    //console.log(lastSundayFormatted);
     */

    const lastMonth = today.setDate(0);
    var lastMonthFormatted = new Date(lastMonth).toISOString().slice(0, 10);

    let currentYear = new Date().getFullYear();
    let previousYear =  currentYear - 1;

    let company = this.$store.getters.getCompany;

    let start_config = previousYear + "-01-01";
    startDate = start_config;
    start_config = start_config + "T00:00:00"

    if (!company.statistic_start_date) {
      this.statistic_start_date = '';
    } else {
      this.statistic_start_date = company.statistic_start_date;

      const company_start_date = new Date(this.statistic_start_date);
      const regular_start_date = new Date(startDate);

      const company_start_time = company_start_date.getTime();
      const regular_start_time = regular_start_date.getTime();

      //console.log(company_start_date.toISOString().slice(0, 10) + ', ' + regular_start_date.toISOString().slice(0, 10));

      if (company_start_time > regular_start_time) {
        startDate = this.statistic_start_date;
        start_config = startDate + "T00:00:00";
      }

    }

    let dates = this.getDates(new Date(start_config), new Date(lastMonthFormatted + "T00:00:00"));
    //console.log('Dates: ', dates);
    //console.log(lastMonthFormatted);

    this.$store.dispatch('setRecordingStartDate', startDate);
    this.$store.dispatch('setRecordingEndDate', lastMonthFormatted);
    this.$store.dispatch('setRecordingDates', dates);

    this.getMonths();
    this.getYears();

    this.getAllRecords();
    this.companyStatistic();

    //console.log(this.getRecordingDates);

  },
  computed: {
    ...mapGetters([
      'getRecordingDates',
      'getRecordingMonths',
      'getUser',
      'getIsLoggedIn',
      'getRecords',
      'getRecordingYears',
      'getCompany',
      'getCompanyStatistic',
    ]),

    salutation() {
      const user = this.getUser;
      let salutation = '';

      //console.log(user);

      if (user.salutation == 1) {

        salutation = 'Herzlich willkommen, Herr ' + user.lastname + '!';

      } else {

        salutation = 'Herzlich willkommen, Frau ' + user.lastname + '!';

      }

      return salutation;
    }
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
        'setRecordingYears',
    ]),
    ...mapGetters([
    ]),
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    getMonths() {

      let dates = this.getRecordingDates;

      //console.log('dates');
      //console.log(dates);

      //month
      let months = {};
      const keys = Object.keys(dates);

      //Dezember-Bug fixen:
      let lastKey = keys.length - 1;
      let lastYearMonth = dates[lastKey]['yearmonth'];

      //console.log('lastYearMonth: ', lastYearMonth);

      //console.log('keys: ', keys);
      keys.forEach((key) => {
        //console.log(`${key}: ${dates[key]}`);
        //console.log('key: ', dates[key] );
        //console.log(dates[key]);
        //console.log('yearmonth')
        //console.log(key, dates[key]['yearmonth']);
        //months[dates[key]['yearmonth']].push(dates[key]);

        //Dezember-Bug fixen:
        if (dates[key]['yearmonth'] <= lastYearMonth) {

          if (months[dates[key]['yearmonth']] === undefined) {
            //console.log('undefined: ', key);
            months[dates[key]['yearmonth']] = dates[key];
          }

        }

      });
      //console.log('monate')
      //console.log(months);

      //this.months = months;

      this.$store.dispatch('setRecordingMonths', months);

      return months;

    },
    getYears() {

      let currentYear = new Date().getFullYear();

      let dates = this.getRecordingDates;

      let years = {};
      const keys = Object.keys(dates);
      keys.forEach((key) => {

        //das aktuelle Jahr nicht nehmen!
        if (dates[key]['year'] < currentYear) {

          //Jahr setzen
          if (years[dates[key]['year']] === undefined) {
            years[dates[key]['year']] = dates[key];
          }

        }

      });

      //console.log(years);

      this.$store.dispatch('setRecordingYears', years);

      //console.log(this.getRecordingYears);

      return years;

    },
    recordingDatesCounter(type) {

      let dates = this.getRecordingDates;

      let records = this.getRecords;

      var turnoverCounter = 0;
      var visitorCounter = 0;
      var datesCounter = dates.length;

      if (records.turnover !== undefined) {
        turnoverCounter = Object.keys(records.turnover).length;
      }

      if (records.visitor !== undefined) {
        visitorCounter = Object.keys(records.visitor).length;
      }

      //console.log(records);
      //console.log(dates);

      //console.log(turnoverCounter);
      //console.log(visitorCounter);
      var diff = ( datesCounter * 2 ) - ( turnoverCounter + visitorCounter );

      //this.countTodoTurnoverWeekly = datesCounter - turnoverCounter;
      //this.countTodoVisitorWeekly = datesCounter - visitorCounter;
      //console.log(diff);
      //console.log(this.getRecordingDates);
      //return this.getRecordingDates.length;

      var out = 0;

      switch(type) {

        default:
        case 'sumWeekly':
          out = diff;
          break;

        case 'visitor':
          out = datesCounter - visitorCounter;
          break;

        case 'turnover':
          out = datesCounter - turnoverCounter;
          break;

      }

      return out;

    },
    recordingMonthCounter(type) {

      let months = this.getRecordingMonths;

      //console.log(this.getUser);

      /*
      let months = {};

      if (Object.keys(this.months).length > 0) {
        months = this.months;
      } else {
        months = this.getMonths();
      }

       */

      let records = this.getRecords;

      var turnoverCounter = 0;
      var visitorCounter = 0;
      var monthsCounter = Object.keys(months).length;

      //console.log('Monate: ', monthsCounter);
      //console.log(months);

      if (records.turnoverMonth !== undefined) {
        turnoverCounter = Object.keys(records.turnoverMonth).length;
      }

      if (records.visitorMonth !== undefined) {
        visitorCounter = Object.keys(records.visitorMonth).length;
      }

      //console.log('turnover ', turnoverCounter);
      //console.log('visitor ', visitorCounter);

      var diff = ( monthsCounter * 2 ) - ( turnoverCounter + visitorCounter );

      var out = 0;

      switch(type) {

        default:
        case 'sumMonthly':
          out = diff;
          break;

        case 'visitor':
          out = monthsCounter - visitorCounter;
          break;

        case 'turnover':
          out = monthsCounter - turnoverCounter;
          break;

      }

      return out;

    },
    recordingYearsCounter(type) {

      let years = this.getRecordingYears;

      //console.log(years);

      let records = this.getRecords;

      var staffCounter = 0;
      var visitorCounter = 0;
      var yearsCounter = Object.keys(years).length;

      //console.log(yearsCounter);

      if (records.staff !== undefined) {
        staffCounter = Object.keys(records.staff).length;
      }

      //console.log(records);

      if (records.visitor_types !== undefined) {
        visitorCounter = Object.keys(records.visitor_types).length * 3;
      }

      //console.log(visitorCounter);

      var diff = ( yearsCounter + (yearsCounter * 3) ) - ( staffCounter + visitorCounter );

      var out = 0;

      switch(type) {

        default:
        case 'sumYears':
          out = diff;
          break;

        case 'visitor':
          out = (yearsCounter * 3) - visitorCounter;
          break;

        case 'staff':
          out = yearsCounter - staffCounter;
          break;

      }

      //console.log(type, out);

      return out;

    },
    async getAllRecords() {

      var cookie = this.$store.getters.getToken;

      var startDate = this.$store.getters.getRecordingStartDate;
      var endDate = this.$store.getters.getRecordingEndDate;

      //console.log('start: ', startDate);
      //console.log('end: ', endDate);

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_records',
            startDate: startDate,
            endDate: endDate,
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);
        this.$store.dispatch('setRecords', {});

      } else {

        //console.log(response.data);
        this.$store.dispatch('setRecords', response.data);

      }
    },
    getFormattedDate(date) {

      var res = date.toISOString().slice(0,10).split(/-/);
      return res[2] + '.' + res[1] + '.' + res[0];

    },
    getDates(startDate, endDate) {

      let monthnames = {
        1: 'Januar',
        2: 'Februar',
        3: 'März',
        4: 'April',
        5: 'Mai',
        6: 'Juni',
        7: 'Juli',
        8: 'August',
        9: 'September',
        10: 'Oktober',
        11: 'November',
        12: 'Dezember',
      };
      //console.log(monthnames);

      //first the function and variable definitions
      let dates = []
      const addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      //now our Sunday check
      let currentDate = startDate;
      /*
      //für Wochentage

      if (currentDate.getDay() > 0) {
        //console.log('not a sunday...adjusting')
        currentDate.setDate(currentDate.getDate() - ((currentDate.getDay() + 6) % 7));

        var new_start_year = currentDate.getFullYear();
        var new_start_month = currentDate.getMonth() + 1;
        var new_start_day = currentDate.getDay();

        //console.log(currentDate.getDay());

        if (new_start_month <= 9) new_start_month = '0' + new_start_month;
        if (new_start_day <= 9) new_start_day = '0' + new_start_day;

        //console.log('neues Dateum');
        this.$store.dispatch('setRecordingStartDate', new_start_year + '-' + new_start_month + "-" + new_start_day);
      }

       */

      console.log(currentDate + ' ' + endDate)
      while (currentDate <= endDate) {
        let endWeekDate = addDays.call(currentDate, 7);

        var week = (new Date(currentDate)).getWeek();
        var old_year = currentDate.getFullYear();
        var new_year = endWeekDate.getFullYear();

        var month = currentDate.getMonth() + 1;
        //console.log(month);

        var year = old_year;

        if (week == 1) {
          year = new_year;
        }

        var month_str = month;

        if (month <= 9) month_str = '0' + month;

        var yearmonth = year.toString() + month_str;

        var week_str = week;

        if (week <= 9) week_str = '0' + week;

        dates.push(
            {
              begin: currentDate,
              begin_formatted: this.getFormattedDate(currentDate),
              end: endWeekDate,
              end_formatted: this.getFormattedDate(endWeekDate),
              week: week,
              week_str: week_str,
              month: month,
              month_str: month_str,
              monthname: monthnames[month],
              year: year,
              yearmonth: yearmonth
            });
        currentDate = addDays.call(currentDate, 7);
      }
      return dates;
    },

    async saveCompanyStatisticStartDate() {

      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'set_company_statistic_start_date',
            statisticStartDate: this.statistic_start_date,
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);

      } else {

        let company = this.getCompany;

        company.statistic_start_date = this.statistic_start_date;

        //console.log(response.data);
        this.$store.dispatch('setCompany', company);

        this.showAlert();

        this.companyStatistic();

      }

    },

    async companyStatistic() {

      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_user_statistic',
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);

      } else {

        this.$store.dispatch('setCompanyStatistic', response.data);

        //console.log(response.data);

      }

    },

  }
}
</script>

<style scoped>

</style>