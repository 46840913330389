<template>
  <b-container>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">
        <h3>Jährliche Datenerfassung</h3>
        <p>Bitte tragen Sie die folgenden Daten ein.<br/>Es werden die folgenden Daten benötigt:</p>
        <ul>
          <li>Anzahl Mitarbeiter</li>
          <li>Anteil Besucher Berlin / Brandenburg in %</li>
          <li>Anteil Besucher Deutschland (ohne Berlin / Brandenburg) in %</li>
          <li>Anteil Besucher international in %</li>
        </ul>
      </b-col>
    </b-row>

    <div
        v-for="date in getRecordingYears"
        :key="date.begin_formatted"
    >
      <b-row
          class="justify-content-md-center"
          align-v="center"
          v-if="findRecord(date.year, 'staff') == 0 || findRecord(date.year, 'visitor_types') == 0"
      >

        <b-col cols="8" md="auto" class="line">

          <h5>{{ date.year }}</h5>

          <b-form-group
              label="Mitarbeiter:"
              :label-for="'staff_' + date.year"
              v-if="findRecord(date.year, 'staff') == 0"
          >
            <b-input-group append-html="" class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-input
                  name="staff"
                  :id="'staff_' + date.year"
                  type="text"
                  placeholder="Anzahl Mitarbeiter"
                  :state="validation('decimal', 'staff', date.year)"
                  v-model.trim="staff[date.year]"
              ></b-form-input>
            </b-input-group>
          </b-form-group>

          <div v-if="true === false">

          <h5 style="margin-bottom: 20px; margin-top: 30px;">Besucher nach Regionen:</h5>

          <b-form-group
              label="Berlin / Brandenburg:"
              :label-for="'visitorBb_' + date.year"
              v-if="findRecord(date.year, 'visitor_types') == 0"
          >
            <b-input-group append-html="%" class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-input
                  name="visitorBb"
                  :id="'visitorBb_' + date.year"
                  type="text"
                  placeholder="Berlin / Brandenburg"
                  :state="validation('visitor_types', 'visitorBb', date.year)"
                  v-model.trim="visitorBb[date.year]"
              ></b-form-input>
            </b-input-group>
            <b-form-invalid-feedback :state="validation('visitor_types', 'visitorBb', date.year)">
              Der Wert darf 100 nicht übersteigen.<br/>Alle drei Besucherdaten müssen insgesamt 100 ergeben.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('visitor_types', 'visitorBb', date.year)"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="Deutschland (ohne Berlin / Brandenburg):"
              :label-for="'visitorBb_' + date.year"
              v-if="findRecord(date.year, 'visitor_types') == 0"
          >
            <b-input-group append-html="%" class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-input
                  name="visitorDe"
                  :id="'visitorDe_' + date.year"
                  type="text"
                  placeholder="Deutschland"
                  :state="validation('visitor_types', 'visitorDe', date.year)"
                  v-model.trim="visitorDe[date.year]"
              ></b-form-input>
            </b-input-group>
            <b-form-invalid-feedback :state="validation('visitor_types', 'visitorDe', date.year)">
              Der Wert darf 100 nicht übersteigen.<br/>Alle drei Besucherdaten müssen insgesamt 100 ergeben.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('visitor_types', 'visitorDe', date.year)"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="International:"
              :label-for="'visitorInt_' + date.year"
              v-if="findRecord(date.year, 'visitor_types') == 0"
          >
            <b-input-group append-html="%" class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-input
                  name="visitorInt"
                  :id="'visitorInt_' + date.year"
                  type="text"
                  placeholder="International"
                  :state="validation('visitor_types', 'visitorInt', date.year)"
                  v-model.trim="visitorInt[date.year]"
              ></b-form-input>
            </b-input-group>
            <b-form-invalid-feedback :state="validation('visitor_types', 'visitorInt', date.year)">
              Der Wert darf 100 nicht übersteigen.<br/>Alle drei Besucherdaten müssen insgesamt 100 ergeben.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('visitor_types', 'visitorInt', date.year)"></b-form-valid-feedback>
          </b-form-group>

          Gesamt: <span
            v-if="getSum(date.year) != 100" class="text-danger"><strong>{{ getSum(date.year) }}%</strong></span>

          <span
              v-else class="text-success"><strong>{{ getSum(date.year) }}%</strong></span>

          </div>

        </b-col>

      </b-row>
    </div>

    <b-row
        class="justify-content-md-center"
        align-v="center"
    >

      <b-col cols="8" md="auto">

        <b-alert
            variant="danger"
            :show="apiError"
            dismissible
        >{{ apiErrorMessage }}</b-alert>

        <b-alert
            variant="success"
            :show="apiSuccess"
            dismissible
        >Vielen Dank! Die Daten wurden gespeichert.</b-alert>

        <b-button
            type="button"
            variant="primary"
            class="mt-4 mb-4 button-primary"
            @click="saveRecords"
        >speichern</b-button>

      </b-col>

    </b-row>




  </b-container>
</template>

<script>
import axios from 'axios';
import { mapMutations, mapGetters, mapActions } from 'vuex';

export default {
  name: "areaRecordingYears",
  data() {
    return {
      staff: {},
      visitorInt: {},
      visitorBb: {},
      visitorDe: {},

      turnover: {},
      visitors: {},

      formDataError: {},
      API_URL: process.env.VUE_APP_API,
      apiError: false,
      apiErrorMessage: '',
      apiSuccess: false,
    }
  },
  mounted () {

    if (!this.$store.getters.getIsLoggedIn) {
      this.$router.push('/login');
    }

  },
  computed: {
    ...mapGetters([
      'getRecordingDates',
      'getRecordingYears',
      'getRecords'
    ]),
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([]),
    ...mapGetters([
      'getToken',
      'getRecords'
    ]),
    findRecord(year, type) {

      let records = this.$store.getters.getRecords[type];

      //console.log(records);
      //console.log(year, week, type);

      if (typeof records === 'undefined') return 0;

      for (const key in records) {
        //console.log(key);
        //console.log(records[key]);

        console.log(records[key]);

        if (records[key].year == year) {
          if (type === 'staff') {

            this.staff[year] = records[key].staff;

            return records[key].staff;

          } else {

            this.visitorBb[year] = records[key].visitor_types;
            this.visitorDe[year] = records[key].visitor_types;
            this.visitorInt[year] = records[key].visitor_types;

            return records[key].visitor_types;

          }
        }
      }
      return 0;

    },
    validation(type, area, key) {

      var ret = false;
      //var data = this.formData[type];
      var data = this[area][key];

      //console.log(this[area]);
      //console.log(area, key, data);

      switch(type) {

        case 'decimal':

          //var reg = /^[0-9]*[,]*[0-9]{0,1,2}$/; /* eslint-disable-line */
          var reg = /^(\d+(?:[\.\,]\d{2})?)$/; /* eslint-disable-line */

          if (reg.test(data)) {
            ret = true;
          } else {
            ret = false;
          }

          break;

        case 'number':

          var reg = /^[0-9]*$/; /* eslint-disable-line */

          if (reg.test(data)) {
            ret = true;
          } else {
            ret = false;
          }

          break;

        case 'visitor_types':

          var reg = /^[0-9]*$/; /* eslint-disable-line */

          if (data === undefined) {

            ret = false;

          } else {

            if (reg.test(data)) {

              if (data > 100) {

                ret = false;

              } else {

                var vBb = Number(this.visitorBb[key]);
                var vDe = Number(this.visitorDe[key]);
                var vInt = Number(this.visitorInt[key]);

                if (isNaN(vBb)) {
                  vBb = 0;
                }
                if (isNaN(vDe)) {
                  vDe = 0;
                }
                if (isNaN(vInt)) {
                  vInt = 0;
                }

                var sum = vBb + vDe + vInt;

                if (sum == 100) {

                  ret = true;

                } else {

                  ret = false;

                }

              }

            } else {
              ret = false;
            }

          }



          break;

        default:
          ret = true;
          break;


      }

      this.formDataError[type] = ret;
      this.isFormError();
      return ret;

    },
    getSum(key) {
      var vBb = Number(this.visitorBb[key]);
      var vDe = Number(this.visitorDe[key]);
      var vInt = Number(this.visitorInt[key]);

      if (isNaN(vBb)) {
        vBb = 0;
      }
      if (isNaN(vDe)) {
        vDe = 0;
      }
      if (isNaN(vInt)) {
        vInt = 0;
      }

      var sum = vBb + vDe + vInt;

      return sum;
    },
    isFormError() {

      var form = this.formDataError;

      //console.log(form);
      if (form.length === 0) return true;

      for (const key in form) {
        //console.log(key);
        if (!form[key]) {
          //console.log(false);
          return false;
        }
      }

      return true;

    },
    saveRecords() {

      this.sendRecords();


    },
    async sendRecords() {

      var cookie = this.$cookies.get('PHPSESSID');
      //console.log(cookie);

      if (cookie == null) {
        cookie = '';
      }

      cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'set_records_annually',
            staff: this.staff,
            region_bb: this.visitorBb,
            region_de: this.visitorDe,
            international: this.visitorInt,
            cookie: cookie,
          }
      );

      console.log(response);

      if (response.data.error !== undefined) {

        //console.log(response);
        //console.log(response.data.error);
        this.apiErrorMessage = response.data.error.join("<br/>");
        this.apiError = true;
        this.apiSuccess = false;

      } else {

        this.apiSuccess = true;
        this.apiError = false;

      }
    },
  }
}
</script>

<style scoped>

.line {
  border-bottom: 2px solid #CCE5FF;
  padding-bottom: 20px;
  padding-top: 20px;
}

</style>